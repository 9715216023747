var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-snackbar',{attrs:{"timeout":"4000","bottom":"","app":""},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(_vm._s(this.snackbarText)+" ")]),_c('v-container',{attrs:{"fluid":"","fixed":""}},[_c('v-row',{staticClass:"child-flex"},[_c('div',[_c('v-toolbar',{style:({
            'border-bottom-width': 'thin !important',
            'border-color': _vm.$vuetify.theme.dark
              ? 'rgb(255,255,255,0.12)'
              : 'rgb(0,0,0,0.12)',
            'border-bottom-style': 'solid !important',
          }),attrs:{"fixed":"","elevation":"0","dense":_vm.dense}},[(_vm.showBackBtn)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.backBtnClicked()}}},[_c('v-icon',[_vm._v("mdi-arrow-left")])],1):_vm._e(),(_vm.showDiscardBtn)?_c('v-btn',{staticClass:"hidden-md-and-up uppercase",attrs:{"icon":"","color":"error"},on:{"click":function($event){_vm.discardBtnAction || _vm.backBtnClicked()}}},[_c('v-icon',[_vm._v("mdi-close-circle")])],1):_vm._e(),(_vm.showDiscardBtn)?_c('v-btn',{staticClass:"mr-4 hidden-sm-and-down uppercase",attrs:{"depressed":"","color":"error"},on:{"click":function($event){_vm.discardBtnAction || _vm.backBtnClicked()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-close-circle")]),_vm._v("Verwerfen ")],1):_vm._e(),_c('v-toolbar-title',[_vm._v(_vm._s(_vm.titel))]),(_vm.isPreview)?_c('v-chip',{staticClass:"ml-4 font-weight-medium teal--text text--darken-5",attrs:{"color":"teal lighten-5"}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("mdi-flask-empty")]),_vm._v("Vorschau")],1):_vm._e(),_c('v-spacer'),_vm._t("before-actions"),_vm._t("actions",function(){return _vm._l((_vm.actions),function(item,index){return _c('div',{key:index},[(item.actionStyle === 'textBtn')?_c('div',[(
                    item.customPermission || _vm.checkPermission(item.permission)
                  )?_c('v-btn',{staticClass:"hidden-sm-and-down uppercase",attrs:{"text":"","disabled":item.disabled,"color":_vm.$vuetify.theme.dark ? '' : 'grey darken-1'},on:{"click":item.function}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(_vm._s(item.icon))]),_c('span',[_vm._v(_vm._s(item.title))])],1):_vm._e(),(
                    item.customPermission || _vm.checkPermission(item.permission)
                  )?_c('v-btn',{staticClass:"hidden-md-and-up",attrs:{"icon":"","text":"","disabled":item.disabled,"color":_vm.$vuetify.theme.dark ? '' : 'grey darken-1'},on:{"click":item.function}},[_c('v-icon',[_vm._v(_vm._s(item.icon))])],1):_vm._e()],1):(item.actionStyle === 'iconBtn')?_c('div',[(
                    item.customPermission || _vm.checkPermission(item.permission)
                  )?_c('v-btn',{staticClass:"hidden-sm-and-down",attrs:{"icon":"","disabled":item.disabled,"color":_vm.$vuetify.theme.dark ? '' : 'grey darken-1'},on:{"click":item.function}},[_c('v-icon',[_vm._v(_vm._s(item.icon))])],1):_vm._e(),(
                    item.customPermission || _vm.checkPermission(item.permission)
                  )?_c('v-btn',{staticClass:"hidden-md-and-up",attrs:{"icon":"","disabled":item.disabled,"color":"success"},on:{"click":item.function}},[_c('v-icon',[_vm._v(_vm._s(item.icon))])],1):_vm._e()],1):(item.actionStyle === 'textBtnSubmit')?_c('div',[(
                    item.customPermission || _vm.checkPermission(item.permission)
                  )?_c('v-btn',{staticClass:"hidden-sm-and-down uppercase mr-1",attrs:{"depressed":"","disabled":item.disabled,"color":item.color || 'success'},on:{"click":item.function}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(_vm._s(item.icon))]),_c('span',[_vm._v(_vm._s(item.title))])],1):_vm._e(),(
                    item.customPermission || _vm.checkPermission(item.permission)
                  )?_c('v-btn',{staticClass:"hidden-md-and-up",attrs:{"icon":"","disabled":item.disabled,"color":item.color || 'success'},on:{"click":item.function}},[_c('v-icon',[_vm._v(_vm._s(item.icon))])],1):_vm._e()],1):(item.actionStyle === 'clipboardBtn')?_c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","disabled":item.disabled},on:{"click":function($event){return _vm.copyToCLipboard()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-link")])],1)]}}],null,true)},[_c('span',[_vm._v("Link in Zwischenablage kopieren")])])],1):(item.actionStyle === 'textBtnMultiple')?_c('div',[(
                    item.customPermission || _vm.checkPermission(item.permission)
                  )?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"hidden-sm-and-down uppercase",attrs:{"text":"","disabled":item.disabled,"color":_vm.$vuetify.theme.dark ? '' : 'grey darken-1'}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v(_vm._s(item.icon))]),_c('span',[_vm._v(_vm._s(item.title))]),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-menu-down")])],1),(
                        item.customPermission ||
                        _vm.checkPermission(item.permission)
                      )?_c('v-btn',_vm._g(_vm._b({staticClass:"hidden-md-and-up uppercase",attrs:{"text":"","disabled":item.disabled,"color":_vm.$vuetify.theme.dark ? '' : 'grey darken-1'}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(item.icon))]),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-menu-down")])],1):_vm._e()]}}],null,true)},[_c('v-list',_vm._l((item.list),function(item,index){return _c('span',{key:index},[(item.divider)?_c('v-divider'):_c('span',[(
                            item.customPermission ||
                            _vm.checkPermission(item.permission)
                          )?_c('v-list-item',{attrs:{"link":"","disabled":item.disabled},on:{"click":item.function}},[(item.icon)?_c('v-list-item-icon',[_c('v-icon',{attrs:{"disabled":item.disabled,"dense":""}},[_vm._v(_vm._s(item.icon))])],1):_vm._e(),_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1):_vm._e()],1)],1)}),0)],1):_vm._e()],1):(item.divider)?_c('div',[_c('v-divider',{staticClass:"mx-1",staticStyle:{"display":"inline"},attrs:{"vertical":""}})],1):_c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(
                        item.customPermission ||
                        _vm.checkPermission(item.permission)
                      )?_c('v-btn',_vm._g(_vm._b({staticClass:"uppercase",attrs:{"icon":"","disabled":item.disabled}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(item.icon))])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.title))])])],1)])})})],2),_vm._t("tab-bar",function(){return [(!!_vm.tabs)?_c('div',[_c('v-tabs',{attrs:{"show-arrows":"","grow":""},model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},[_vm._l((_vm.tabs),function(tab){return _c('v-tab',{key:tab.id,attrs:{"to":tab.route,"disabled":tab.disabled,"exact-path":""}},[_vm._v(" "+_vm._s(tab.name)+" ")])}),_vm._l((_vm.tabs),function(tab){return _c('v-tab-item',{key:tab.id,attrs:{"value":tab.route,"disabled":tab.disabled}})})],2)],1):_vm._e()]})],2)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }